import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
//eslint-disable-next-line
import { css } from 'styled-components/macro';

import Header from '../headers/light.js';

import { ReactComponent as SvgDecoratorBlob1 } from '../../images/svg-decorator-blob-1.svg';
import DesignIllustration from '../../images/MDO-37.jpg';
import CustomersLogoStripImage from '../../images/MEDIO/VSA.png';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col-reverse lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const SubHeading = tw.h4`text-xl text-mediogray-400 leading-tight mt-4`;
const Paragraph = tw.p`my-5 lg:my-8 text-base`;

const Actions = styled.div`
  ${tw`relative text-center mx-auto lg:mx-0 flex items-center justify-center sm:justify-start`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-medioblue-600 hover:border-gray-500`}
  }
  button {
    ${tw`w-full right-0 top-0 bottom-0 bg-medioblue-600 hover:bg-medioblue-700 text-gray-100 font-bold my-4 sm:my-2 rounded-full p-6 flex items-center justify-center sm:leading-none focus:outline-none transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

const CustomersLogoStrip = styled.div`
  ${tw`mt-12 lg:mt-20`}
  p {
    ${tw`text-center uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`mt-4 md:w-40 w-10 lg:pr-16 xl:pr-32`}
  }
`;

export default ({ roundedHeaderButton }) => {
  return (
    <>
      <Header roundedHeaderButton={roundedHeaderButton} />
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>
              Welcome to{' '}
              <span tw="text-medioblue-600">Medio Sports Agency</span>
            </Heading>
            <SubHeading>
              Shaping{' '}
              <span tw="text-medioblue-600">the Future of Sports Careers</span>
            </SubHeading>
            <Paragraph>
              Stay informed. Get the latest news, updates, and opportunities in
              the sports world. Join our exclusive WhatsApp community for
              real-time updates and insights in the sports industry. Click to
              connect now!
            </Paragraph>
            <Actions>
              {/* <input type="text" placeholder="Your E-mail Address" /> */}
              <a
                href="https://chat.whatsapp.com/CJ5ekSiuTCW4yBB2obkhkg"
                target="_blank"
              >
                <button>Unlock Your Sports Journey!</button>
              </a>
            </Actions>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img
                tw="rounded-lg min-w-0 w-full max-w-lg xl:max-w-xl"
                src={DesignIllustration}
                alt="Design Illustration"
              />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
        {/* <CustomersLogoStrip>
              <p>Our Partners</p>
              <div tw="flex flex-row">
              <img src={CustomersLogoStripImage} alt="Our Customers" />
              <img src={CustomersLogoStripImage} alt="Our Customers" />
              <img src={CustomersLogoStripImage} alt="Our Customers" />
              <img src={CustomersLogoStripImage} alt="Our Customers" />
              <img src={CustomersLogoStripImage} alt="Our Customers" />
              <img src={CustomersLogoStripImage} alt="Our Customers" />
              <img src={CustomersLogoStripImage} alt="Our Customers" />
              </div>
            </CustomersLogoStrip> */}
      </Container>
    </>
  );
};
