import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { ContentWithPaddingXl, Container } from 'components/misc/Layouts.js';
import {
  SectionHeading as Heading,
  Subheading as SubheadingBase,
} from 'components/misc/Headings.js';
import { ReactComponent as SvgDecoratorBlob1 } from 'images/svg-decorator-blob-7.svg';
import { ReactComponent as SvgDecoratorBlob2 } from 'images/svg-decorator-blob-8.svg';
import alex from '../../images/alex.png';
import teti from '../../images/teti.png';
import thomas from '../../images/thomas.jpeg';
import philip from '../../images/philip.png';

const Subheading = tw(SubheadingBase)`text-center`;
const Testimonials = tw.div`flex flex-col lg:flex-row items-center lg:items-stretch`;
const TestimonialContainer = tw.div`mt-16 lg:w-1/3`;
const Testimonial = tw.div`px-4 text-center max-w-xs mx-auto flex flex-col items-center`;
const Image = tw.img`w-20 h-20 rounded-full`;
const Quote = tw.blockquote`mt-5 text-gray-600 font-medium leading-loose h-40`;
const CustomerName = tw.p`mt-5 text-gray-900 font-semibold uppercase text-lg tracking-wide text-primary-500`;
const CustomerTitle = tw.p`text-gray-600 italic uppercase text-xs tracking-wide`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 top-0 h-56 w-56 opacity-15 transform -translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-64 w-64 opacity-15 transform translate-x-2/3 text-yellow-500`}
`;

export default ({
  subheading = 'Testimonials',
  heading = "Customer's Review",
  testimonials = [
    {
      imageSrc: alex,
      quote:
        'After joining the Everton Camp, I am very happy because I got a new friends and experienced training with the Everton Coaches.',
      customerName: 'Alexander Darren',
      customerType: 'Everton Camp Participant',
    },
    {
      imageSrc: teti,
      quote:
        "The event is very good, it was well organized. It's amazing that Medio Sports Agency can accommodate this event, because it was the first in South-East Asia.",
      customerName: 'Teti',
      customerType: 'Everton Camp Parents',
    },
    {
      imageSrc: thomas,
      quote:
        'Big thanks to Medio Sports Agency for welcoming us, we feel like being the part of the family. It was fantastic. ',
      customerName: 'Thomas Carney',
      customerType: 'Everton Coach',
    },
    {
      imageSrc: philip,
      quote:
        'I really love this event because I can learn for my weakness in football, like dribble, pass, and defending.',
      customerName: 'Philip Henderson',
      customerType: 'Everton Camp Participant',
    },
  ],
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading tw="text-3xl md:text-3xl lg:text-4xl xl:text-5xl">
          {heading}
        </Heading>
        <Testimonials>
          {testimonials.map((testimonial, index) => (
            <TestimonialContainer key={index}>
              <Testimonial>
                <Image src={testimonial.imageSrc} />
                <Quote>"{testimonial.quote}"</Quote>
                <CustomerName>{testimonial.customerName}</CustomerName>
                <CustomerTitle>{testimonial.customerType}</CustomerTitle>
              </Testimonial>
            </TestimonialContainer>
          ))}
        </Testimonials>
      </ContentWithPaddingXl>

      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
