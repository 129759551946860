import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
//eslint-disable-next-line
import { css } from 'styled-components/macro';
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from 'components/misc/Headings.js';
import { SectionDescription } from 'components/misc/Typography.js';

import defaultCardImage from 'images/shield-icon.svg';

import { ReactComponent as SvgDecoratorBlob3 } from 'images/svg-decorator-blob-3.svg';

import SupportIconImage from 'images/support-icon.svg';
import ShieldIconImage from 'images/shield-icon.svg';
import CustomizeIconImage from 'images/customize-icon.svg';
import FastIconImage from 'images/fast-icon.svg';
import ReliableIconImage from 'images/reliable-icon.svg';
import SimpleIconImage from 'images/simple-icon.svg';
import {
  FaRegCalendarCheck,
  FaBook,
  FaHandshake,
  FaGlobe,
  FaHeadset,
} from 'react-icons/fa';

const Container = tw.div`relative`;

const Actions = styled.div`
  ${tw`relative text-center mx-auto lg:mx-0 flex items-center justify-center sm:justify-center`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-medioblue-600 hover:border-gray-500`}
  }
  button {
    ${tw`w-full justify-center right-0 top-0 bottom-0 bg-medioblue-600 hover:bg-medioblue-700 text-gray-100 font-bold my-4 sm:my-2 rounded-full p-6 flex items-center justify-center sm:leading-none focus:outline-none transition duration-300`}
  }
  .adminIcon {
    ${tw`mr-4 text-lg`}
  }
`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center mx-auto py-10 md:py-12`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(
  SectionHeading,
)`w-full text-3xl md:text-3xl lg:text-4xl xl:text-5xl`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/4 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-3xl text-center rounded-full p-5 flex-shrink-0 text-medioblue-600`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`tracking-wide font-bold text-2xl leading-none h-16`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({
  cards = null,
  heading = 'Amazing Features',
  subheading = 'Features',
  description = '',
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      icon: FaRegCalendarCheck,
      title: 'Sports Event Organizer',
      description:
        'We offer sports event organizer services, such as Camps, Coaching Clinics, Showcases, Tournaments, or Coach Workshops.',
    },
    {
      icon: FaBook,
      title: 'Sports Education',
      description:
        'We offer comprehensive training and knowledge-sharing opportunities for Players, Coaches, and Sports Enthusiasts.',
    },
    {
      icon: FaHandshake,
      title: 'Athlete Representation',
      description:
        'We are committed to helping Athletes achieve their goals, both on and off the field, and guiding them towards long-term success in their sporting careers.',
    },
    {
      icon: FaGlobe,
      title: 'Sports Tourism',
      description:
        'We provide seamless and immersive experiences that blend the love of sports with the joy of exploration in breathtaking destinations.',
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                {card.icon ? (
                  <card.icon />
                ) : (
                  <img src={defaultCardImage} alt="Default Icon" />
                )}
              </span>
              <span className="textContainer">
                <p className="title">{card.title || 'Fully Secure'}</p>
                <p className="description">
                  {card.description ||
                    'Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud.'}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <Actions>
        {/* <input type="text" placeholder="Your E-mail Address" /> */}
        <a href="https://wa.me/6288210375719" target="_blank">
          <button>
            <FaHeadset className="adminIcon" />
            {''}Contact Our Admin for More Insight!{' '}
          </button>
        </a>
      </Actions>
      {/* <DecoratorBlob /> */}
    </Container>
  );
};
